<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Checklists</h1>
        </v-col>

        <v-col class="pl-12">
          <v-text-field
            label="Search Checklists"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.checklistDialog.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New List</span>
            </v-tooltip></v-row
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="grid-row">
            <v-data-table
              :headers="tableHeaders"
              :items="checklists"
              no-data-text="No checklists"
              :items-per-page="-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  color="green lighten-4 green--text"
                  :to="{
                    name: 'module-sbpm-checklists-individual',
                    params: { checklistId: item.id },
                  }"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <ChecklistDialog ref="checklistDialog" />
    <v-dialog v-model="deleteProperty.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Property</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteProperty.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChecklistDialog from "./components/ChecklistDialog.vue";

export default {
  components: {
    ChecklistDialog,
  },

  data() {
    return {
      deleteProperty: {
        dialog: false,
        property: {},
        loading: false,
      },

      searchTerm: "",
      breadcrumbs: [
        {
          text: "Checklists",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],

      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    checklists() {
      let checklists = this.$store.getters["sbpm/checklistsStore/all"];

      if (this.searchTerm !== "") {
        checklists = checklists.filter((p) => {
          const name = p.name.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm);
        });
      }

      return checklists;
    },
  },

  methods: {
    openDelete(property) {
      this.deleteProperty.property = property;
      this.deleteProperty.dialog = true;
    },

    resetDelete() {
      this.deleteProperty.dialog = false;
      this.deleteProperty.property = {};
      this.deleteProperty.loading = false;
    },

    saveDelete() {
      this.deleteProperty.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/deleteProperty", {
          appId: this.$route.params.id,
          propertyId: this.deleteProperty.property.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteProperty.loading = false;
        });
    },
  },
};
</script>
